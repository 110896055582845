
<template>
   <h1>Masks</h1>

   
</template>
<script setup>
import { ref } from 'vue';
import { query, collection, /* addDoc, */ getDocs } from "firebase/firestore";
// the firestore instance
import db from '../firebase/init.js';

const tasks = ref( [1,2,3] );

const q = query( collection( db, 'tasks' ) );
// data to send

const querySnapshot = await getDocs( q );
querySnapshot.forEach( ( doc ) => {
   // doc.data() is never undefined for query doc snapshots
   console.log( doc.id, " => ", doc.data() );
   // tasks.value.push( doc.data() );
} );

console.log( tasks.value );



</script>