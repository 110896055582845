// import { storage } from '../firebase/init.js';
import { getStorage, ref as refFirebase, getDownloadURL, uploadBytes } from "firebase/storage";
const storage = getStorage();

logToTask();

function logToTask( id, text ) {
   console.log( 'logToTask', id, text );

   const fileRef = refFirebase( storage, 'tasks/' + id + '/log.txt' );



   getDownloadURL( fileRef )
      .then( ( url ) =>
         fetch( url )
         , () => {
            return uploadBytes( fileRef, new Blob( [ '\n\n--------------\n\n' ], { type: 'text/html' } ) )
         } )
      .then( data => data.text(), ()=>'' )
      .then( oldText => {
         // console.log( { text } );
         return uploadBytes( fileRef, new Blob( [ oldText + '\n\n--------------\n\n' + text ], { type: 'text/html' } ) )
      } )
      .then( ( snapshot ) => {
         console.log( 'Uploaded a blob or file!' );
         console.log( snapshot );
      } )
      .catch( ( e ) => {
         // Handle any errors
         console.log( e );
      } );

}

async function getTaskFile( id, what ) {
   console.log( ' getTaskConfig', id );
   const fileRef = refFirebase( storage, 'tasks/' + id + '/' + what );
   const url = await getDownloadURL( fileRef );
   const res = await fetch( url );
   const text = await res.text();
   return {url, text}
}

export { logToTask, getTaskFile }
