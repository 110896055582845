<script setup >
import { ref, onMounted, toRaw } from 'vue';
import { /* writeBatch, */ query, collection,  /* getDocs, */ onSnapshot, setDoc, doc, addDoc, deleteDoc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";

import IonIosInformationOutline from '../components/signs/IonIosInformationOutline.vue';
import { db, storage } from '../firebase/init.js';
import { ref as refStorage, listAll, /* getBytes, */ getBlob, getDownloadURL } from 'firebase/storage';
import { logToTask, getTaskFile } from '../Logger/logger.js';

import { /* useRouter, */ useRoute } from 'vue-router'
// import LoggerComp from '../components/LoggerComp.vue';

console.log( storage );

const ORDERED_STATES =
   [
      'newTask',
      'readyForConfigGeneration',
      'configGenerated'
   ];

let modal;


const tasks = ref( [] );
const tasksOrder = ref( [] );

const experiments = ref( [] );
const experimentsOrder = ref( [] );
const experimentsColors = ref( [] );

const form = ref( {
   a: 5.1,

   region_x: 1,
   region_y: 1,
   region_z: 1,

   basis: 0.0001,

   boundary_top: undefined,
   boundary_bottom: undefined,
   boundary_side: undefined,

   thermodynamic_type: undefined,

   t: 100,

   subtasks_enabled: false,
   tmax: 300,
   ntsep: 10,
   tsteps_enabled: false,
   tstep: 30,

   neigh_modify: 10,

   timestep: 0.1,

   run: 500,
   nsave: 1000,
   thermo: 5,

   // reports

   density_t0: false,
   defectiveness_t0: false,
   radial_distribution_function_t0: false,

   density_t: false,
   defectiveness_t: false,
   radial_distribution_function_t: false,
   diffusion_coefficient_t: false,

   thermal_conductivity_t0: false,
   heat_capacity_t0: false,
   heat_capacity_t: false,
   thermal_conductivity_t: false,

   // options

   taskName: '',
   taskDescription: '',
} );

const taskConfig = ref( 'No config yet.' )
const taskConfigURL = ref( 'No config URL yet.' )

const isNewTask = ref( true );
const currentTaskId = ref( '' );

const orderMin = ref( 0 );
const orderMax = ref( 0 );

function reOrder() {
   console.log( 'reOrder' );

   orderMin.value = 0;
   orderMax.value = 0;

   let nonOrderedTasksCounter = 0;

   // taskId => order у несортируемых задач
   const orderPerIdNonOrdered = tasksOrder.value
      .filter( tId => !ORDERED_STATES.includes( tasks.value.find( t => t.id === tId )?.state ) )
      .reduce( ( acc, taskId, idx ) => {
         acc[ taskId ] = idx;
         return acc;
      }, {} );

   console.log( { orderPerIdNonOrdered } );

   // присваиваем order у несортируемых задач
   tasks.value.forEach( task => {
      if ( !ORDERED_STATES.includes( task.state ) ) {
         // task.order = nonOrderedTasksCounter;
         task.order = orderPerIdNonOrdered[ task.id ];
         nonOrderedTasksCounter++;
      } else {
         task.order = undefined;
         delete task.order;
      }
   } );


   console.log( tasks.value.map( el => toRaw( el ) ) );

   orderMin.value = nonOrderedTasksCounter;

   // taskId => order у сортируемых задач
   const orderPerId = tasksOrder.value.filter( id => tasks.value.find( t => t.id === id )?.order === undefined )
      .reduce( ( acc, taskId, idx ) => {

         acc[ taskId ] = idx;
         orderMax.value = Math.max( orderMax.value, idx );
         return acc;
      }, {} );

   orderMax.value += nonOrderedTasksCounter;

   console.log( '----------------------' );
   console.log( orderPerId, orderMax.value, orderMin.value );

   const branches = {};

   // присваиваем order у сортируемых задач
   for ( let task of tasks.value ) {
      if ( task.parent ) {
         if ( !branches[ task.parent ] ) branches[ task.parent ] = 0;
         branches[ task.parent ] += 0.0001;
         if ( !task.order ) task.order = orderPerId[ task.parent ] + branches[ task.parent ];
      } else {
         if ( orderPerId[ task.id ] > -1 ) task.order = orderPerId[ task.id ] /* ?? -1 */ + nonOrderedTasksCounter;
      }


   }
}

const route = useRoute();

const currentExperiment = ref( {} );

let currentExperimentId;


onMounted( async () => {

   console.log( 'route', route.query );

   currentExperimentId = route.query.experimentId;

   /* ------------------------------- tasksOrder ------------------------------- */

   // onSnapshot( doc( db, 'tasksOptions', 'order' ), ( doc ) => {

   //    console.log( doc );

   //    tasksOrder.value = doc.data().order;

   //    reOrder();

   // } );


   /* ---------------------------- experimentsOrder ---------------------------- */

   onSnapshot( doc( db, 'experimentsOptions', 'order' ), ( doc ) => {

      console.log( doc );

      experimentsOrder.value = doc.data().order;

      reOrder();

   } );


   /* ---------------------------- experimentsColors --------------------------- */

   onSnapshot( doc( db, 'experimentsOptions', 'colors' ), ( doc ) => {

      console.log( doc );

      experimentsColors.value = doc.data().colors;

      reOrder();

   } );


   /* ------------------------------- experiments ------------------------------ */

   const experimentsQ = query( collection( db, 'experiments' ) );

   onSnapshot( experimentsQ, ( snapshot ) => {

      console.log( 'experiments changes' );
      console.log( snapshot );
      const arr = [];

      snapshot.forEach( ( doc ) => {
         console.log( doc.id, " => ", doc.data() );
         let data = { ...doc.data(), id: doc.id, };
         arr.push( data );

         if ( currentExperimentId === doc.id ) {
            currentExperiment.value = data;
            tasksOrder.value = data.tasksOrder;
            setTimeout( () => {

            }, 500 )
         }

      } );

      experiments.value = arr;

      reOrder();

   } );


   /* ---------------------------------- tasks --------------------------------- */

   const tasksQ = query( collection( db, 'tasks' ) );

   onSnapshot( tasksQ, ( snapshot ) => {

      console.log( snapshot );
      const arr = [];

      const tasksOrderIdByIndex = tasksOrder.value.reduce( ( acc, el, idx ) => {
         acc[ el ] = idx;
         return acc;
      }, {} );

      console.log( { tasksOrderIdByIndex } );

      // массив задач arr
      snapshot.forEach( ( doc ) => {
         console.log( doc.id, " => ", doc.data() );
         let data = { ...doc.data(), id: doc.id, order: tasksOrderIdByIndex[ doc.id ] };
         arr.push( data );
         // if ( data.order ) {
         //    orderPerId[ data.id ] = data.order;
         // }

      } );



      // arr.sort( ( a, b ) => a.order - b.order );

      tasks.value = arr.filter( el => el.experimentId === currentExperimentId );

      snapshot.docChanges().forEach( async ( change ) => {

         // console.log( { change } );

         const task = change.doc.data();

         if ( change.type === "added" ) {
            console.log( "New task: ", task );
            await updateDoc( doc( db, 'experiments', task.experimentId ), { tasksOrder: arrayUnion( change.doc.id ) } )
            // await updateDoc( doc( db, 'tasksOptions', 'order' ), {
            //    order: arrayUnion( change.doc.id )
            // } );
         }
         if ( change.type === "modified" ) {
            console.log( "Modified task: ", task );
         }
         if ( change.type === "removed" ) {
            console.log( "Removed task: ", task );
            await updateDoc( doc( db, 'experiments', task.experimentId ), { tasksOrder: arrayRemove( change.doc.id ) } )
            // await updateDoc( doc( db, 'tasksOptions', 'order' ), {
            // order: arrayRemove( change.doc.id )
            // } );
         }
      } );

      reOrder();
   } );

   // let counter = 0;
   // const mjInterval = setInterval( () => {
   //    try {
   //       counter++;
   //       MathJax.typeset();
   //       clearInterval( mjInterval );
   //    }
   //    catch ( err ) {
   //       console.log( err );
   //       if ( counter > 50 ) {
   //          clearInterval( mjInterval );
   //          throw new Error( 'Перезагрузите страницу!!!!' )
   //       }

   //    }
   // }, 100 );

   const popoverTriggerList = document.querySelectorAll( '[data-bs-toggle="popover"]' )
   const popoverList = [ ...popoverTriggerList ].map( popoverTriggerEl => new bootstrap.Popover( popoverTriggerEl ) )
   // setTimeout( () => {
   // const collapseElementList = document.querySelectorAll( '.collapse' )
   // collapseList = [ ...collapseElementList ].map( collapseEl => new bootstrap.Collapse( collapseEl ) )
   // }, 1000 );

   console.log( popoverList,
      // collapseList
   );

} )

// async function makeOrder() {
//    console.log( 'makeOrder' );



//    const batch = writeBatch( db );
//    let order = 1;
//    // const colRef = collection( db, 'tasks' );
//    for ( let t of tasks.value ) {
//       if ( t.parent ) delete t.order;
//       else {
//          if ( t.order === order ) {
//             order++;
//             continue;
//          }
//          t.order = order;
//          order++;
//       }
//       const doc2 = doc( db, 'tasks', t.id );
//       batch.set( doc2, { ...t } )
//       // await setDoc( doc( colRef, t.id ), { ...t } )

//    }
//    await batch.commit();
// }

function resetForm() {

   form.value.a = 5.1;

   form.value.region_x = 1;
   form.value.region_y = 1;
   form.value.region_z = 1;

   form.value.basis = 0.0001;

   form.value.boundary_top = undefined;
   form.value.boundary_bottom = undefined;
   form.value.boundary_side = undefined;

   form.value.thermodynamic_type = undefined;

   form.value.t = 100;

   form.value.subtasks_enabled = false;
   form.value.tmin = 100;
   form.value.tmax = 300;
   form.value.ntsep = 10;
   form.value.tsteps_enabled = false;
   form.value.tstep = 30;

   form.value.neigh_modify = 10;

   form.value.timestep = 0.1;

   form.value.run = 500;
   form.value.nsave = 1000;
   form.value.thermo = 5;

   // reports

   form.value.density_t0 = false;
   form.value.defectiveness_t0 = false;
   form.value.radial_distribution_function_t0 = false;

   form.value.density_t = false;
   form.value.defectiveness_t = false;
   form.value.radial_distribution_function_t = false;
   form.value.diffusion_coefficient_t = false;

   form.value.thermal_conductivity_t0 = false;
   form.value.heat_capacity_t0 = false;
   form.value.heat_capacity_t = false;
   form.value.thermal_conductivity_t = false;

   // options

   form.value.taskName = '';
   form.value.taskDescription = '';
}

function addNewTaskModal() {
   console.log( 'addNewTaskModal' );
   /* global bootstrap  */
   isNewTask.value = true;
   currentTaskId.value = '';
   // form.value.taskName = '';
   // form.value.taskDescription = '';
   resetForm();
   modal = new bootstrap.Modal( '#modalAddNewTask' );
   modal.show();
}

async function addNewTask() {
   console.log( 'addNewTask' );
   document.querySelector( '#submitForm1' ).click();
   document.querySelector( '#submitForm2' ).click();

   if ( document.forms[ 'taskOptionsForm' ].checkValidity() && document.forms[ 'reportOptionsForm' ].checkValidity() ) {
      const f1 = new FormData( document.forms[ 'taskOptionsForm' ] );
      const f2 = new FormData( document.forms[ 'reportOptionsForm' ] );
      const newTaskData = { ...Object.fromEntries( [ ...f1.entries() ] ), ...Object.fromEntries( [ ...f2.entries() ] ) };
      newTaskData.state = 'newTask';
      // TO DO



      newTaskData.lastUpdater = 'currentUser';
      if ( isNewTask.value ) {
         newTaskData.creator = 'currentUser';

         newTaskData.dateCreated = Date.now();
      }
      newTaskData.dateUpdated = Date.now();
      newTaskData.experimentId = currentExperimentId;

      // newTaskData.order = tasks.value.length + 1;

      let docRef;
      if ( isNewTask.value ) {
         docRef = await addDoc( collection( db, 'tasks' ), { ...newTaskData } );
         logToTask( docRef.id, 'Задача создана' )
      } else {
         await setDoc( doc( db, 'tasks', currentTaskId.value ), { ...newTaskData } );
      }

      // makeOrder();
      console.log( docRef?.id );
      // if ( docRef?.id || updateTimestamp ) {
      // await updateDoc( doc( db, 'tasksOptions', 'order' ), {
      //    order: arrayUnion( docRef.id )
      // } )
      modal.hide();
      setTimeout( () => {
         if ( docRef?.id ) document.querySelector( '[_id="' + docRef.id + '"]' )?.scrollIntoView( { block: "center", behavior: "smooth" } )
      }, 1000 );
      // }
   }
}

async function deleteTask( event ) {
   console.log( 'deleteTask' );
   if ( !confirm( 'Уверены?' ) ) return;
   const delId = event.target.closest( '.taskItem' )?.getAttribute( '_id' );
   console.log( delId );
   if ( delId ) {

      const index = tasks.value.findIndex( el => el.id === delId );
      console.log( 'index:', index );
      const data = { ...tasks.value[ index ] };
      delete data.order;
      console.log( 'data:', data );

      await addDoc( collection( db, 'tasksTrash' ), data );
      // await updateDoc( doc( db, 'tasksOptions', 'order' ), {
      //    order: arrayRemove( delId )
      // } )
      await deleteDoc( doc( db, "tasks", delId ) );
   }
}

function submitTask( event ) {
   event.preventDefault();
   console.log( 'submitTask' );
   return false;
}


function checkNumber( event ) {
   console.log( 'checkNumber' );
   try {
      if ( event.target.getAttribute( 'numberType' ) === 'float' ) {
         event.target.value = event.target.value.replaceAll( /[^0-9.]/g, '' );
      } else {
         event.target.value = event.target.value.replaceAll( /[^0-9]/g, '' );
      }
      const min = +event.target.getAttribute( 'min' );
      const max = +event.target.getAttribute( 'max' );
      if ( +event.target.value > max ) event.target.value = max;
      if ( +event.target.value < min ) event.target.value = min;

      const e = new Event( 'input' );
      event.target.dispatchEvent( e );

   } catch ( error ) {
      console.log( error );
   }
}

async function changeOrder( id, direction ) {
   console.log( 'changeOrder' );

   const orderPerId = tasksOrder.value.reduce( ( acc, el, idx ) => {
      acc[ el ] = idx;
      return acc;
   }, {} );

   const order = [ ...tasksOrder.value ];

   const currentIdx = orderPerId[ id ];

   let newIdx;

   switch ( direction ) {
      case 0:
         newIdx = 0;
         break;
      case 100500:
         newIdx = order.length - 1;
         break;
      case -1:
         newIdx = currentIdx - 1;
         break;
      case 1:
         newIdx = currentIdx + 1;
         break;
   }

   const temp = order.splice( currentIdx, 1 )[ 0 ];
   order.splice( newIdx, 0, temp );

   await updateDoc( doc( db, 'experiments', currentExperimentId ), {
      tasksOrder: order
   } )

   // const batch = writeBatch( db );
   // const doc1 = doc( db, 'tasks', id );
   // batch.set( doc1, { order: newOrder }, { merge: true } );

   // if ( !toEnd ) {

   //    const id2 = document.querySelector( '[_order="' + ( newOrder ) + '"]' )?.getAttribute( '_id' );

   //    if ( id2 ) {
   //       const doc2 = doc( db, 'tasks', id2 );
   //       batch.set( doc2, { order: currentOrder }, { merge: true } )
   //    } else {
   //       console.log( 'errrorrr' );
   //       return;
   //    }
   // }
   // await batch.commit();

}

// function collapse( event ) {
//    console.log( 'collapse' );
//    console.log( event );
//    // return false;
//    // setTimeout( () => {
//    //    if ( event.target.getAttribute( 'aria-expanded' ) == "true" ) return;

//    //    /* const cl =  */event.target.closest( '.accordion' ).querySelector( '.collapse' ).classList.toggle( 'show' );

//    //    // if ( cl ) {
//    //    //    const el = new bootstrap.Collapse( cl );
//    //    //    console.log( 'el', el );
//    //    //    el.hide();
//    //    // }
//    // }, 500 );
// }

function moreInfo( task ) {
   console.log( 'moreInfo', task );
   task.more = !task.more;
   let moreInfo = Object.entries( task )
      .filter( el => el[ 0 ] !== 'moreInfo' )
      .filter( el => el[ 0 ] !== 'dateCreated' )
      .filter( el => el[ 0 ] !== 'dateUpdated' )
      .filter( el => el[ 0 ] !== 'creator' )
      .filter( el => el[ 0 ] !== 'taskName' )
      .filter( el => el[ 0 ] !== 'experimentId' )
      .filter( el => el[ 0 ] !== 'id' )
      .filter( el => el[ 0 ] !== 'taskDescription' )
      .filter( el => el[ 0 ] !== 'lastUpdater' )
      .filter( el => el[ 0 ] !== 'more' )
      .filter( el => el[ 0 ] !== 'order' )
      // .filter( el => el[ 0 ] !== 'subtasks_enabled' )
      .filter( el => el[ 0 ] !== 'state' ).sort( ( a, b ) => a[ 0 ] < b[ 0 ] ? -1 : 1 )
   task.moreInfo = JSON.stringify( Object.fromEntries( moreInfo )
      , null, 4 )
      .replace( / /g, ' ' ).replaceAll( '\n', '<br>' ).replace( '{<br>', '' ).replace( /<br>}$/, '' )
}

function editTask( t ) {
   console.log( 'editTask' );
   resetForm();
   isNewTask.value = false;
   currentTaskId.value = t.id;
   modal = new bootstrap.Modal( '#modalAddNewTask' );
   modal.show();
   for ( let [ key, value ] of Object.entries( t ) ) {
      console.log( key, value );
      form.value[ key ] = value === 'on' ? true : value;
   }
}

function createTaskConfig( t ) {
   console.log( 'createTaskConfig' );
   console.log( t.id );
   updateDoc( doc( db, 'tasks', t.id ), { state: 'readyForConfigGeneration' } );
}

function calculateTask( t ) {
   console.log( 'calculateTask' );
   console.log( t.id );
   updateDoc( doc( db, 'tasks', t.id ), { state: 'readyForCalc' } );
}

function gotoExperiments() {
   location.href = '/experiments';
}

function lookAtTaskConfigOrLog( id, what ) {
   console.log( 'lookAtTaskConfig' );
   modal = new bootstrap.Modal( '#configModal' );
   modal.show();
   taskConfig.value = '';
   getTaskFile( id, what ).then( data => {
      taskConfig.value = data?.text;
      taskConfigURL.value = data?.url;
   } ).catch( e => {
      taskConfig.value = e;
      taskConfigURL.value = "#";
   } );
}

const resultFolder = ref( {} );
const resultFolderLoading = ref( '' );

async function readResults( t ) {
   console.log( t.id );
   resultFolderLoading.value = 'Загружаем...'

   const listRef = refStorage( storage, '/tasks/' + t.id );

   const folders = {};
   const res = await listAll( listRef );
   console.log( res );

   res.prefixes.forEach( ( folderRef ) => {
      console.log( folderRef.name );
      if ( Number( folderRef.name ) ) {
         folders[ folderRef.name ] = { textValues: {}, images: {} }
      }
      // All the prefixes under listRef.
      // You may call listAll() recursively on them.
   } );
   res.items.forEach( ( itemRef ) => {
      console.log( itemRef );
      // All the items under listRef.
   } );

   console.log( { folders } );
   for ( let i in folders ) {
      const subfolderRef = refStorage( storage, '/tasks/' + t.id + '/' + i )
      const res = await listAll( subfolderRef );
      // console.log( { res } );

      for ( let file of res.items ) {
         console.log( { file } );
         console.log( file.name );

         if ( file.name.split( '.' ).slice( -1 )[ 0 ] === 'txt' ) {
            const txt = await getBlob( refStorage( storage, '/tasks/' + t.id + '/' + i + '/' + file.name ) );
            const textValue = await txt.text();
            console.log( textValue );
            folders[ i ][ 'textValues' ][ file.name ] = textValue;
         } else {
            folders[ i ][ 'images' ][ file.name ] = await getDownloadURL( refStorage( storage, '/tasks/' + t.id + '/' + i + '/' + file.name ) )
         }
      }

   }
   resultFolderLoading.value = 'Загружено успешно!';
   setTimeout( () => {
      resultFolderLoading.value = '';
   }, 3000 );
   resultFolder.value = folders;

   console.log( { folders } );

   // console.log( folders );
   // } ).catch( ( error ) => {
   //    console.log( error );
   //    // Uh-oh, an error occurred!
   // } );
}

const resultSelected = ref();
function changeResult( idx ) {
   console.log( idx );

   resultSelected.value = idx;

}

const resultImagesCaption = {
   "cp.png": "Коэффициент теплоемкости",
   "diffusion.png": "Коэффициент само-диффузии",
   "kappa.png": "Коэффициент теплопроводности",
   "rdf.png": "Радиальные функции распределения"
}
const resultTextsCaption = {
   "atoms.txt": "Количество атомов:",
   "dimensions.txt": "Размерность расчетной ячейки:",
}

</script>

<template>
   <!-- <LoggerComp/> -->
   <div class="container">
      <div class="raw">
         <div class="col-12">

            <div class="exHeader">

               <h1>Задачи эксперимента “{{ currentExperiment?.name || '###' }}”
               </h1>
               <!-- <select class="form-select form-select-inline" aria-label="Default select example"
                                                                                                                                 v-model="currentExperiment">
                                                                                                                                 <option selected disabled value="kkk">Open this select menu</option>
                                                                                                                                 <option class="bigOption" v-for="ex, eIdx in experiments" :key="eIdx" :value="eIdx">{{ ex.name }}
                                                                                                                                 </option>
                                                                                                                              </select> -->

               <!-- <MathJax latex="x_a+y^2" :block="true"></MathJax> -->

               <button class="btn btn-outline-primary" @click="addNewTaskModal">Добавить задачу</button>
               <button class="btn btn-outline-warning" @click="gotoExperiments">К экспериментам</button>
               <!-- <button class="btn btn-outline-primary" @click="makeOrder">Упорядочить</button> -->
            </div>
         </div>
         <div class="col-12">

            <transition-group name="list" tag="div" style="display: flex; flex-direction: column;">
               <div class="taskItem" v-for="t, tIdx in tasks" :key="tIdx" :class="{ child: t.parent }" :_id="t.id"
                  :_order="t.order"
                  :style="'order:' + t.order * 10000 + '; border-left-color: #' + experimentsColors[currentExperiment?.colorIndex] + ';'">
                  <!-- {{ t }} -->
                  <!-- <p>{{ currentExperiment.colorIndex }}</p> -->
                  <p style="color:#000">{{ t.order }}: {{ t.id }}</p>
                  <div v-if="ORDERED_STATES.includes(t.state)" class="btn-group mb-3" role="group" aria-label="Order">
                     <button class="btn btn-outline-primary" @click="changeOrder(t.id, 0)"
                        :disabled="t.order === orderMin">ToTop</button>
                     <button class="btn btn-outline-primary" @click="changeOrder(t.id, -1)"
                        :disabled="t.order === orderMin">Up</button>
                     <button class="btn btn-outline-primary" @click="changeOrder(t.id, +1)"
                        :disabled="t.order >= orderMax">Down</button>
                     <button class="btn btn-outline-primary" @click="changeOrder(t.id, 100500)"
                        :disabled="t.order >= orderMax">ToBottom</button>
                  </div>
                  <h2>{{ t.taskName }}</h2>
                  <p>{{ t.taskDescription }}</p>
                  <div v-if="!t.parent" style="display:flex; flex-wrap: wrap;">
                     <div class="btn-group mb-3" role="group" aria-label="Order">
                        <button @click="editTask(t)" v-if="t.state === 'newTask'"
                           class="btn btn-outline-primary">Редактировать</button>
                        <button v-if="t.state === 'newTask'" class="btn btn-outline-primary"
                           @click="createTaskConfig(t)">Создать конфиг</button>
                        <button v-if="t.state !== 'newTask' && t.state !== 'readyForConfigGeneration'"
                           @click="lookAtTaskConfigOrLog(t.id, 'config0.lmp')" class="btn btn-outline-primary">Посмотреть
                           config</button>
                        <button @click="calculateTask(t)" v-if="t.state === 'readyForCalc'"
                           class="btn btn-outline-primary">Вычислить</button>
                        <button class="btn btn-outline-primary" disabled>Клонировать</button>
                        <button class="btn btn-outline-danger" @click="deleteTask">Удалить</button>
                     </div>
                  </div>
                  <p>Задача создана: {{ t.dateCreated ? new Date(t.dateCreated).toLocaleString() : 'нет данных' }}
                     , кем: {{ t.creator || 'нет данных' }}</p>
                  <p>Дата изменения: {{ t.dateUpdated ? new Date(t.dateUpdated).toLocaleString() : 'нет данных' }}
                     , кем: {{ t.lastUpdater || 'нет данных' }}</p>
                  <p>Статус задачи : {{ t.state }} </p>
                  <div class="btn-group mb-2" role="group" aria-label="Basic example">
                     <button @click="lookAtTaskConfigOrLog(t.id, 'log.lammps')" class="btn btn-outline-info">Посмотреть
                        логи ЛАМПЫ</button>
                     <button @click="lookAtTaskConfigOrLog(t.id, 'log.txt')" class="btn btn-outline-info">Посмотреть
                        логи задачи</button>
                  </div>
                  <br>
                  <button class="btn btn-outline-info" @click="moreInfo(t)">Начальные значения:</button>
                  <p v-show="t.more" class="more" v-html="t.moreInfo">
                  </p>
                  <div class="mt-3" v-if="t.state === 'done'">
                     <button class="btn btn-success" @click="readResults(t)">Посмотреть результаты</button>
                     {{ resultFolderLoading }}
                     <!-- {{ resultFolder }} -->
                     <!-- <p>Толщина: </p> -->
                     <div class="mt-4">
                        <label v-for="el, idx in resultFolder" :key="el" class="me-5">
                           <input type="radio" name="resultRadio" :value="idx" :thickness="idx" @input="changeResult(idx)">
                           {{ idx }}
                           <!-- {{ el }} -->
                        </label>
                     </div>

                     <div v-for="el, idx in resultFolder" :key="idx" class="resultImages"
                        :class="{ active: resultSelected === idx }">

                        <div style="padding-top: 2rem; padding-bottom: 2rem;">
                           <span v-for="text, fname in el.textValues" :key="fname">
                              <strong>{{ resultTextsCaption[fname] }} {{ text }} </strong><br>
                           </span>
                        </div>
                        <div></div>
                        <div v-for="src, fname in el.images" :key="src" style="text-align: center;">
                           <a :href="src" target="_blank">
                              <img :src="src" alt="aaaa" style="width: 100%">
                              <p>{{ resultImagesCaption[fname] }}</p>
                           </a>
                        </div>

                     </div>
                  </div>
                  <!-- <div class="progress mt-3">
                                                                                                                                    <div class="progress-bar" role="progressbar" aria-label="Basic example" style="width: 25%"
                                                                                                                                       aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                                                                 </div> -->

               </div>
            </transition-group>

         </div>
      </div>
   </div>
   <div class="modal fade" id="modalAddNewTask" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
         <div class="modal-content">
            <div class="modal-header">
               <h1 class="modal-title fs-5" id="exampleModalLabel">{{ isNewTask ? 'Добавить' : 'Обновить' }} задачу</h1>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

               <div>
                  <form name="taskOptionsForm" @submit.prevent="submitTask">
                     <input type="submit" value="submit" id="submitForm1">

                     <div class="mb-3">
                        <label for="taskName" class="form-label fw-bold">Название задачи</label>
                        <input required type="text" class="form-control" id="taskName" v-model="form.taskName"
                           name="taskName" placeholder="Название задачи" autocomplete="off">
                     </div>
                     <div class="mb-3">
                        <label for="taskDescription" class="form-label fw-bolder">Описание задачи</label>
                        <textarea required class="form-control" id="taskDescription" rows="3" autocomplete="off"
                           v-model="form.taskDescription" name="taskDescription"></textarea>
                     </div>

                     <h5>Параметры задачи</h5>

                     <div class="mb-3">
                        <label for="formControlInput1" class="form-label">Параметр кристаллической решётки,
                           <!-- <MathJax latex="\unicode{x212B}"></MathJax>, -->
                           <MathJax latex="\mathring A"></MathJax>,
                           <span class="minmax">[5.1…5.7]</span>
                        </label>
                        <div class="input-group mb-3">
                           <input @change="checkNumber" required type="text" v-model="form.a" name="a" class="form-control"
                              id="formControlInput1" numberType="float"
                              title="Необходимо число (float) в указанном диапазоне." min="5.1" max="5.7"
                              pattern="[0-9]+([\.][0-9]+)?" placeholder="5.1" autocomplete="off">
                           <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[5.1…5.7]"
                              data-bs-content="Характерное расстояние между атомами в кристаллической решётке">
                              <IonIosInformationOutline class="cyan" />
                           </span>
                        </div>
                     </div>

                     <div class="mb-3">
                        <label for="formControlInput21" class="form-label">Вектор трансляции, ед, <span
                              class="minmax">[1…50]</span></label>
                        <div class="input-group mb-3">
                           <span class="input-group-text" tabindex="-1">
                              <MathJax latex="x:"></MathJax>
                           </span>
                           <input @change="checkNumber" required v-model="form.region_x" type="text" name="region_x"
                              autocomplete="off" class="form-control" id="formControlInput21" placeholder="1"
                              numberType="float" min="1" max="50">
                           <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[1…50]"
                              data-bs-content="Длина вектора трансляции для расчётной ячейки. Задаёт геометрические размеры моделируемой ячейки.">
                              <IonIosInformationOutline class="cyan" />
                           </span>
                        </div>
                        <div class="input-group mb-3">
                           <span class="input-group-text">
                              <MathJax latex="y:"></MathJax>
                           </span>
                           <input @change="checkNumber" required v-model="form.region_y" type="text" name="region_y"
                              autocomplete="off" class="form-control" id="formControlInput22" placeholder="1" min="1"
                              max="50">
                           <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[1…50]"
                              data-bs-content="Длина вектора трансляции для расчётной ячейки. Задаёт геометрические размеры моделируемой ячейки.">
                              <IonIosInformationOutline class="cyan" />
                           </span>
                        </div>
                        <div class="input-group mb-3">
                           <span class="input-group-text">
                              <MathJax latex="z:"></MathJax>
                           </span>
                           <input @change="checkNumber" required v-model="form.region_z" type="text" name="region_z"
                              autocomplete="off" class="form-control" id="formControlInput23" placeholder="1" min="1"
                              max="50">
                           <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[1…50]"
                              data-bs-content="Длина вектора трансляции для расчётной ячейки. Задаёт геометрические размеры моделируемой ячейки.">
                              <IonIosInformationOutline class="cyan" />
                           </span>
                        </div>
                     </div>

                     <div class="mb-3">
                        <label for="formControlInput3" class="form-label">Начальное отклонение атомов от положения
                           равновесия,
                           <!-- <MathJax latex="\unicode{x212B}"></MathJax>, -->
                           <MathJax latex="\mathring A"></MathJax>,
                           <span class="minmax">[0.0001…0.01]</span>
                        </label>
                        <div class="input-group mb-3">
                           <!-- <span class="input-group-text">\(z:\) </span> -->
                           <input @change="checkNumber" required v-model="form.basis" type="text" name="basis"
                              autocomplete="off" class="form-control" id="formControlInput3" placeholder="1"
                              numberType="float" min="0.0001" max="0.01">
                           <span class="input-group-text">
                              * {{ form.a }} = {{ (form.basis * form.a).toFixed(6) }}
                           </span>
                           <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[0.0001…0.01]"
                              data-bs-content="Начальное отклонение атомов от положения равновесия, что “эффективно” позволяет учитывать аморфность">
                              <IonIosInformationOutline class="cyan" />
                           </span>
                        </div>
                     </div>

                     <p class="mt-4">Тип граничных условий
                        <span data-bs-toggle="popover"
                           data-bs-content="Начальное отклонение атомов от положения равновесия, что “эффективно” позволяет учитывать аморфность">
                           <IonIosInformationOutline class="cyan" />
                        </span>
                     </p>
                     <div class="mb-3 d-grid" style="grid-template-rows: 1fr 1fr 1fr;">
                        <div class="d-grid" style="grid-template-columns: 1fr 1fr 1fr;">
                           <div class="form-check form-check-inline">
                              <label>верхняя:</label>
                           </div>
                           <div class="form-check form-check-inline">
                              <input required class="form-check-input" type="radio" v-model="form.boundary_top"
                                 name="boundary_top" id="inlineRadio11" value="p">
                              <label class="form-check-label" for="inlineRadio11">periodic</label>
                           </div>
                           <div class="form-check form-check-inline">
                              <input required class="form-check-input" type="radio" v-model="form.boundary_top"
                                 name="boundary_top" id="inlineRadio12" value="s">
                              <label class="form-check-label" for="inlineRadio12">scattering</label>
                           </div>
                        </div>
                        <div class="d-grid" style="grid-template-columns: 1fr 1fr 1fr;">
                           <div class="form-check form-check-inline">
                              <label>нижняя:</label>
                           </div>
                           <div class="form-check form-check-inline">
                              <input required class="form-check-input" type="radio" v-model="form.boundary_bottom"
                                 name="boundary_bottom" id="inlineRadio21" value="p">
                              <label class="form-check-label" for="inlineRadio21">periodic</label>
                           </div>
                           <div class="form-check form-check-inline">
                              <input required class="form-check-input" type="radio" v-model="form.boundary_bottom"
                                 name="boundary_bottom" id="inlineRadio22" value="s">
                              <label class="form-check-label" for="inlineRadio22">scattering</label>
                           </div>
                        </div>

                        <div class="d-grid" style="grid-template-columns: 1fr 1fr 1fr;">
                           <div class="form-check form-check-inline">
                              <label>боковые границы:</label>
                           </div>
                           <div class="form-check form-check-inline">
                              <input required class="form-check-input" type="radio" v-model="form.boundary_side"
                                 name="boundary_side" id="inlineRadio31" value="p">
                              <label class="form-check-label" for="inlineRadio31">periodic</label>
                           </div>
                           <div class="form-check form-check-inline">
                              <input required class="form-check-input" type="radio" v-model="form.boundary_side"
                                 name="boundary_side" id="inlineRadio32" value="s">
                              <label class="form-check-label" for="inlineRadio32">scattering</label>
                           </div>
                        </div>
                     </div>




                     <p>Тип термодинамического ансамбля
                        <span data-bs-toggle="popover"
                           data-bs-content="определяет сохраняемые при вычисление макроскопические термодинамические параметры">
                           <IonIosInformationOutline class="cyan" />
                        </span>
                     </p>
                     <div class="mb-3">
                        <div class="mb-3 d-grid" style="grid-template-columns: 1fr 1fr 1fr; padding-left: 24px;">
                           <!-- <div class="form-check form-check-inline">
                                                                                                                                             <label></label>
                                                                                                                                          </div> -->
                           <div class="form-check form-check-inline">
                              <input required class="form-check-input" type="radio" v-model="form.thermodynamic_type"
                                 name="thermodynamic_type" id="inlineRadio41" value="nve">
                              <label class="form-check-label" for="inlineRadio41">NVE</label>
                           </div>
                           <div class="form-check form-check-inline">
                              <input required class="form-check-input" type="radio" v-model="form.thermodynamic_type"
                                 name="thermodynamic_type" id="inlineRadio42" value="nvt">
                              <label class="form-check-label" for="inlineRadio42">NVT</label>
                           </div>
                           <div class="form-check form-check-inline">
                              <input required class="form-check-input" type="radio" v-model="form.thermodynamic_type"
                                 name="thermodynamic_type" id="inlineRadio43" value="npt" disabled>
                              <label class="form-check-label" for="inlineRadio43">NPT</label>
                           </div>
                        </div>
                     </div>



                     <div v-show="!form.subtasks_enabled" class="mb-3">
                        <label for="t" class="form-label">Температура системы, К, <span class="minmax">[100…600]</span>
                        </label>
                        <div class="input-group mb-3">
                           <input @change="checkNumber" required type="text" name="t" class="form-control" id="t"
                              autocomplete="off" placeholder="100" v-model="form.t" numberType="int" min="100" max="600">

                           <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[100…600]"
                              data-bs-content="термодинамическая макроскопическая температура, сохраняемая в одном вычислении">
                              <IonIosInformationOutline class="cyan" />
                           </span>
                        </div>
                     </div>


                     <div class="mb-3 bg-light p-3">
                        <div class="mb-3  form-check  form-check-inline">
                           <input class="form-check-input" type="checkbox" id="subtasks_enabled"
                              v-model="form.subtasks_enabled" name="subtasks_enabled">
                           <label class="form-check-label" for="subtasks_enabled">
                              <b> Сформировать подзадачи </b>
                           </label>
                        </div>



                        <div v-show="form.subtasks_enabled" class="mb-3">
                           <label for="tmin" class="form-label">Минимальная температура системы, К, <span
                                 class="minmax">[100…600]</span>
                           </label>
                           <div class="input-group mb-3">
                              <input @change="checkNumber" required type="text" name="tmin" class="form-control" id="tmin"
                                 autocomplete="off" placeholder="100" v-model="form.tmin" numberType="int" min="100"
                                 max="600">

                              <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[100…600]"
                                 data-bs-content="??????">
                                 <IonIosInformationOutline class="cyan" />
                              </span>
                           </div>
                        </div>




                        <div v-show="form.subtasks_enabled" class="mb-3">
                           <label for="tmax" class="form-label">Максимальное значение температуры, К, <span
                                 class="minmax">[101…601]</span>
                           </label>
                           <div class="input-group mb-3">
                              <input @change="checkNumber" required type="text" name="tmax" class="form-control" id="tmax"
                                 autocomplete="off" v-model="form.tmax" numberType="float" min="101" max="601">

                              <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[101…601]"
                                 data-bs-content="термодинамическая макроскопическая температура, сохраняемая в одном вычислении">
                                 <IonIosInformationOutline class="cyan" />
                              </span>
                           </div>
                        </div>



                        <div v-show="form.subtasks_enabled" class="mb-3">
                           <label for="ntsep" class="form-label">Количество температурных точек для подзадач, шт, <span
                                 class="minmax">[2…50]</span>
                           </label>
                           <div class="input-group mb-3">
                              <input @change="checkNumber" required type="text" name="ntsep" class="form-control"
                                 id="ntsep" autocomplete="off" v-model="form.ntsep" numberType="int" min="2" max="50">

                              <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[2…50]"
                                 data-bs-content="количество температурных точек для подзадач, шт">
                                 <IonIosInformationOutline class="cyan" />
                              </span>
                           </div>
                        </div>



                        <div v-show="form.subtasks_enabled" class="mb-3">
                           <label for="tstep" class="form-label">Шаг по температуре, К, <span
                                 class="minmax">[0.001…100]</span>
                           </label>
                           <div class="input-group mb-3">
                              <!-- <input @change="checkNumber" required type="text" name="tstep" class="form-control"
                                                                                                                                                id="tstep" autocomplete="off" v-model="form.tstep" numberType="float" min="0.001"
                                                                                                                                                max="100"> -->

                              <input @change="checkNumber" required type="text" name="tstep" class="form-control"
                                 id="tstep" :value="(form.tmax - form.tmin) / form.ntsep" readonly>

                              <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[0.001…100]"
                                 data-bs-content="шаг по температуре для генерации подзадач ">
                                 <IonIosInformationOutline class="cyan" />
                              </span>
                           </div>
                        </div>





                     </div>


                     <div class="mb-3">
                        <label for="neigh_modify" class="form-label">Временной интервал обновления списка соседей,
                           шагов, (как часто обновляемся?)
                           <span class="minmax">[1…10]</span>
                        </label>
                        <div class="input-group mb-3">
                           <input @change="checkNumber" numberType="int" required type="number" name="neigh_modify"
                              autocomplete="off" class="form-control" id="neigh_modify" placeholder="100"
                              v-model="form.neigh_modify" min="1" max="10">

                           <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[1…10]"
                              data-bs-content="количество временных шагов интегратора через которое обновляется список соседей для каждого атома (для аналоги списка Верле)">
                              <IonIosInformationOutline class="cyan" />
                           </span>
                        </div>
                     </div>

                     <div class="mb-3">
                        <label for="timestep" class="form-label">Временной шаг интегратора, ps,
                           <span class="minmax">[0.001…0.1]</span>
                        </label>
                        <div class="input-group mb-3">
                           <input @change="checkNumber" required type="text" name="timestep" class="form-control"
                              autocomplete="off" id="timestep" placeholder="100" v-model="form.timestep" numberType="float"
                              min="0.001" max="0.1">

                           <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[0.001…0.1]"
                              data-bs-content="временной шаг по которому интегрируются уравнения движения (выбирается достаточно малым для корректного разрешения динамики межатомного взаимодействия)">
                              <IonIosInformationOutline class="cyan" />
                           </span>
                        </div>
                     </div>


                     <div class="mb-3">
                        <label for="run" class="form-label">Количество шагов вычисления, шт,
                           <span class="minmax">[100…10000]</span>
                        </label>
                        <div class="input-group mb-3">
                           <input @change="checkNumber" required type="number" name="run" class="form-control" id="run"
                              autocomplete="off" placeholder="100" v-model="form.run" min="100" max="10000"
                              numberType="int">

                           <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[100…10000]"
                              data-bs-content="максимальное количество шагов симуляции">
                              <IonIosInformationOutline class="cyan" />
                           </span>
                        </div>
                     </div>


                     <div class="mb-3">
                        <label for="nsave" class="form-label">Количество шагов сохранения данных, шт,
                           <span class="minmax">[10…10000]</span>
                        </label>
                        <div class="input-group mb-3">
                           <input @change="checkNumber" required type="number" name="nsave" class="form-control"
                              autocomplete="off" id="nsave" placeholder="100" v-model="form.nsave" numberType="int"
                              min="10" max="10000">

                           <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[10…10000]"
                              data-bs-content="количество шагов симуляции, которое будет сохранено">
                              <IonIosInformationOutline class="cyan" />
                           </span>
                        </div>
                     </div>

                     <div class="mb-3">
                        <label for="thermo" class="form-label">Временной шаг сохранения, шт,
                           <span class="minmax">[1…10]</span>
                        </label>
                        <div class="input-group mb-3">
                           <input @change="checkNumber" required type="number" name="thermo" class="form-control"
                              autocomplete="off" id="thermo" placeholder="100" v-model="form.thermo" numberType="int"
                              min="1" max="10">

                           <span class="input-group-text" data-bs-toggle="popover" data-bs-title="[1…10]"
                              data-bs-content="временной шаг сохранения">
                              <IonIosInformationOutline class="cyan" />
                           </span>
                        </div>
                     </div>



                  </form>
               </div>
               <div>
                  <form name="reportOptionsForm" @submit.prevent="submitTask">
                     <input type="submit" value="submit" id="submitForm2">

                     <h5>Параметры отчётов</h5>

                     <h6 class="mb-3">структурные</h6>


                     <div class="form-check mb-3 ms-2">
                        <input class="form-check-input" type="checkbox" id="density_t0" name="density_t0"
                           v-model="form.density_t0">
                        <label class="form-check-label" for="density_t0">
                           плотность <MathJax latex="\rho(T_0)"></MathJax> (<em>число</em>)
                        </label>
                     </div>


                     <div class="form-check mb-3 ms-2">
                        <input class="form-check-input" type="checkbox" id="defectiveness_t0" name="defectiveness_t0"
                           v-model="form.defectiveness_t0">
                        <label class="form-check-label" for="defectiveness_t0">
                           дефектность <MathJax latex="\gamma(T_0)"></MathJax> (<em>число</em>)
                        </label>
                     </div>


                     <div class="form-check mb-3 ms-2">
                        <input class="form-check-input" type="checkbox" id="radial_distribution_function_t0"
                           name="radial_distribution_function_t0" v-model="form.radial_distribution_function_t0">
                        <label class="form-check-label" for="radial_distribution_function_t0">
                           радиальная функция распределения <MathJax latex="g(r, T_{0})"></MathJax> (<em>график</em>)
                        </label>
                     </div>

                     <div class="form-check mb-3 ms-2">
                        <input class="form-check-input" type="checkbox" id="density_t" name="density_t"
                           v-model="form.density_t">
                        <label class="form-check-label" for="density_t">
                           плотность <MathJax latex="\rho(T)"></MathJax> (<em>график</em>)
                        </label>
                     </div>


                     <div class="form-check mb-3 ms-2">
                        <input class="form-check-input" type="checkbox" id="defectiveness_t" name="defectiveness_t"
                           v-model="form.defectiveness_t">
                        <label class="form-check-label" for="defectiveness_t">
                           дефектность <MathJax latex="\gamma(T)"></MathJax> (<em>график</em>)
                        </label>
                     </div>


                     <div class="form-check mb-3 ms-2">
                        <input class="form-check-input" type="checkbox" id="radial_distribution_function_t"
                           name="radial_distribution_function_t" v-model="form.radial_distribution_function_t">
                        <label class="form-check-label" for="radial_distribution_function_t">
                           радиальная функция распределения <MathJax latex="g(r, T)"></MathJax> (<em>семейство графиков
                              для каждого
                              <MathJax latex="T"></MathJax>
                           </em>)
                        </label>
                     </div>


                     <div class="form-check mb-3 ms-2">
                        <input class="form-check-input" type="checkbox" id="diffusion_coefficient_t"
                           name="diffusion_coefficient_t" v-model="form.diffusion_coefficient_t">
                        <label class="form-check-label" for="diffusion_coefficient_t">
                           Коэффициент диффузии <MathJax latex="D(T)"></MathJax> (<em>график</em>)
                        </label>
                     </div>



                     <h6 class="mt-3">теплофизические</h6>


                     <div class="form-check mb-3 ms-2">
                        <input class="form-check-input" type="checkbox" id="thermal_conductivity_t0"
                           name="thermal_conductivity_t0" v-model="form.thermal_conductivity_t0">
                        <label class="form-check-label" for="thermal_conductivity_t0">
                           теплопроводность <MathJax latex="\lambda(T_0)"></MathJax> (<em>число</em>)
                        </label>
                     </div>


                     <div class="form-check mb-3 ms-2">
                        <input class="form-check-input" type="checkbox" id="heat_capacity_t0" name="heat_capacity_t0"
                           v-model="form.heat_capacity_t0">
                        <label class="form-check-label" for="heat_capacity_t0">
                           теплоемкость <MathJax latex="c_v(T_0)"></MathJax> (<em>число</em>)
                        </label>
                     </div>


                     <div class="form-check mb-3 ms-2">
                        <input class="form-check-input" type="checkbox" id="heat_capacity_t" name="heat_capacity_t"
                           v-model="form.heat_capacity_t">
                        <label class="form-check-label" for="heat_capacity_t">
                           теплоемкость <MathJax latex="c_V(T)"></MathJax> (<em>график</em>)
                        </label>
                     </div>


                     <div class="form-check mb-3 ms-2">
                        <input class="form-check-input" type="checkbox" id="thermal_conductivity_t"
                           name="thermal_conductivity_t" v-model="form.thermal_conductivity_t">
                        <label class="form-check-label" for="thermal_conductivity_t">
                           теплопроводность <MathJax latex="\lambda(T)"></MathJax> (<em>график</em>)
                        </label>
                     </div>

                     <br>
                     <!-- <hr> -->

                     <!-- <h3>электрофизические</h3>
                                                                                                                                    диэлектрическая постоянная (возможно, не будем вычислять).
                                                                                                                                    <hr> -->
                  </form>
               </div>


            </div>

            <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отменить</button>
               <button type="button" class="btn btn-primary" @click="addNewTask">{{ isNewTask ? 'Добавить' : 'Обновить'
               }}
                  задачу</button>
            </div>
         </div>
      </div>
   </div>
   <div class="modal fade" id="configModal" tabindex="-1" aria-labelledby="configModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
         <div class="modal-content">
            <div class="modal-header">
               <h1 class="modal-title fs-5" id="configModalLabel">Просмотр</h1>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

               <pre style="overflow: initial;">{{ taskConfig }}</pre>
            </div>
            <div class="modal-footer">
               <button v-if="taskConfigURL !== '#'" type="button" class="btn btn-link">
                  <a :href="taskConfigURL">Скачать</a>
               </button>
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
               <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
         </div>
      </div>
   </div>
</template>

<style lang="scss">
.taskItem {
   padding: 20px;
   margin: 20px 0;
   border: 1px solid #ddd;
   border-radius: 0.375rem;
   border-left: 16px solid #56CCF2;

   &.child {
      background-color: #ddd;
      margin-left: 40px;
   }
}

.list-move,
/* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
   transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
   opacity: 0;
   transform: translateX(300px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
   position: absolute;
}

#modalAddNewTask .modal-body {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 32px;
   overflow: auto;

   &>div:nth-child(2) {
      position: relative;

      &:before {
         content: '';
         position: absolute;
         width: 1px;
         height: 100%;
         left: -16px;
         top: 0;
         background-color: #ddd;
      }
   }

   @media (max-width:991px) {
      grid-template-columns: 1fr;
   }

   .minmax {
      color: #777;
      white-space: nowrap;
   }

   .input-group-text[data-bs-toggle="popover"] {
      padding-left: 6px;
      padding-right: 6px;
   }

   .cyan {
      color: rgb(122, 136, 239);
   }

   #submitForm1,
   #submitForm2 {
      display: none;
   }
}

.bigOption {
   padding: 30px;
   margin: 30px;
}

p.more {
   column-count: 4;

   @media (max-width: 1199px) {
      column-count: 3;
   }

   @media (max-width: 991px) {
      column-count: 2;
   }

   @media (max-width: 560px) {
      column-count: 1;
   }
}

.exHeader {
   margin-top: 16px;
   display: grid;
   grid-template-columns: repeat(3, auto);
   grid-gap: 1rem;

   @media (max-width: 768px) {
      column-count: 2;
      grid-template-columns: 1fr;
   }
}

.resultImages {
   display: none;

   &.active {
      display: grid;
      grid-template-columns: 1fr 1fr;
   }
}
</style>