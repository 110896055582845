<script setup>

import { ref, onMounted } from 'vue';
import {
   // writeBatch, 
   query,
   collection,
   addDoc,
   // getDocs,
   onSnapshot,
   // setDoc, 
   doc,
   // addDoc, 
   // deleteDoc, 
   updateDoc,
   arrayUnion,
   arrayRemove,
   deleteDoc
} from "firebase/firestore";

import { db } from '../firebase/init.js';
// import { async } from '@firebase/util';

import { useRouter, /* useRoute */ } from 'vue-router';


const experiments = ref( [] );
const experimentsOrder = ref( [] );
const experimentsColors = ref( [] );
const experimentColor = ref( 'dddddd' );

const experimentName = ref( '' );
const experimentDescription = ref( '' );
const experimentColorIndex = ref( 0 );
const experimentEnabled = ref( false );
const experimentId = ref( '' );

/* global bootstrap  */
let modal;
let curExNumber = ref( 0 );

async function delay( ms ) {
   return new Promise( res => {
      setTimeout( res, ms )
   } )
}

onMounted( async () => {



   onSnapshot( doc( db, 'experimentsOptions', 'order' ), ( doc ) => {

      console.log( doc );

      experimentsOrder.value = doc.data().order;

   } );

   onSnapshot( doc( db, 'experimentsOptions', 'colors' ), ( doc ) => {

      console.log( doc );

      experimentsColors.value = doc.data().colors;

   } );


   const experimentsQ = query( collection( db, 'experiments' ) );




   onSnapshot( experimentsQ, async ( snapshot ) => {

      console.log( snapshot );
      const arr = [];


      if ( snapshot.length ) {
         while ( !experimentsOrder.value.length ) {
            await delay( 1000 );
         }
      }

      const experimentsOrderIdByIndex = experimentsOrder.value.reduce( ( acc, el, idx ) => {
         acc[ el ] = idx;
         return acc;
      }, {} );

      console.log( { experimentsOrderIdByIndex } );




      // console.log( 3000 );
      // await delay( 3000 );
      // console.log( 3001 );

      // массив задач arr
      snapshot.forEach( ( doc ) => {
         console.log( doc.id, " => ", doc.data() );
         let data = { ...doc.data(), id: doc.id, order: experimentsOrderIdByIndex[ doc.id ] };
         arr.push( data );
      } );



      arr.sort( ( a, b ) => a.order - b.order );
      experiments.value = arr;

      snapshot.docChanges().forEach( async ( change ) => {

         console.log( { change } );

         if ( change.type === "added" ) {
            console.log( "New task: ", change.doc.data() );
            await updateDoc( doc( db, 'experimentsOptions', 'order' ), {
               order: arrayUnion( change.doc.id )
            } );
         }
         if ( change.type === "modified" ) {
            console.log( "Modified task: ", change.doc.data() );
         }
         if ( change.type === "removed" ) {
            console.log( "Removed task: ", change.doc.data() );
            await updateDoc( doc( db, 'experimentsOptions', 'order' ), {
               order: arrayRemove( change.doc.id )
            } );
         }
      } );
   } );
} );


function showModal( el, idx ) {
   modal = new bootstrap.Modal( '#modalEditExperiment' );

   if ( idx === 100500 ) {
      console.log( 'новый эксперимент' );
      curExNumber.value = experiments.value.length;
      experimentName.value = '';
      experimentDescription.value = '';
      experimentColorIndex.value = 0;
      experimentColor.value = experimentsColors.value[ experimentColorIndex.value ];
      experimentEnabled.value = false;
      experimentId.value = '';
   } else {
      curExNumber.value = idx;
      experimentName.value = el.name;
      experimentDescription.value = el.description;
      experimentColorIndex.value = el.colorIndex;
      experimentColor.value = experimentsColors.value[ experimentColorIndex.value ];
      experimentEnabled.value = el.enabled;
      experimentId.value = el.id;
   }
   modal.show();
}

async function saveData() {
   console.log( 'saveData' );
   console.log( 'id:', experimentId.value );
   document.getElementById( 'submitForm' ).click();
   if ( !document.forms[ 'formEx' ].checkValidity() ) {
      console.log( 'форма не валидна' );
      return;
   }
   let docRef;
   if ( experimentId.value ) {
      docRef = await updateDoc( doc( db, "experiments", experimentId.value ), {
         name: experimentName.value,
         description: experimentDescription.value,
         colorIndex: experimentColorIndex.value,
         enabled: experimentEnabled.value,
         dateUpdated: +new Date(),
      }, { merge: true } );
   } else {
      docRef = await addDoc( collection( db, "experiments" ), {
         name: experimentName.value,
         description: experimentDescription.value,
         colorIndex: experimentColorIndex.value,
         enabled: experimentEnabled.value,
         dateCreated: +new Date(),
         dateUpdated: +new Date(),
         tasksOrder: [],
      } );
   }
   console.log( 2222, docRef, docRef?.id );
   if ( docRef?.id ) {
      console.log( 111 );
   }

}

function submitFromEx( event ) {
   event.preventDefault();
   return false;
}

async function deleteExperiment( ex ) {
   console.log( 'deleteExperiment' );
   if ( confirm( 'Уверены?' ) ) {
      await deleteDoc( doc( db, 'experiments', ex.id ) )
   }
}

const router = useRouter();

function goTasks( id ) {
   console.log( 'goTasks', id );

   router.push( { name: 'tasks', query: { experimentId: id } } )
}
</script>

<template>
   <div>
      <div class="container">
         <div class="raw">
            <div class="col-12 pt-3">
               <h1>Экпериментов: {{ experiments.length }}</h1>
               <button class="btn btn-primary" @click="showModal({}, 100500)">+ Новый эксперимент</button>

               <div class="experiments">
                  <div class="experimentItem" v-for="ex, idx in experiments" :key="idx"
                     :class="ex.enabled ? '' : 'disabledExperiment'"
                     :style="'order: ' + ex.order + '; color: white; background-color: #' + experimentsColors[ex.colorIndex]">
                     <h2>{{ ex.name }}</h2>
                     <p>{{ ex.description }}</p>
                     <!-- <em>{{ ex }}</em> -->
                     <!-- <br> -->
                     <p>Создан: {{ new Date(ex.dateCreated)?.toLocaleDateString() }}</p>
                     <p>Измененен: {{ new Date(ex.dateUpdated)?.toLocaleDateString() }}</p>
                     <p>Задач: {{}} (завершено ##)</p>
                     <!-- <p>Цвет:
                        <span
                           :style="'color: white; width: 3rem; background-color: #' + experimentsColors[ex.colorIndex]">&nbsp;
                           {{ experimentsColors[ex.colorIndex] }} &nbsp;
                        </span>
                     </p> -->
                     <button class="btn btn-primary" @click="showModal(ex, idx)">Редактировать</button>
                     <button class="btn btn-danger" @click="deleteExperiment(ex)">Удалить</button>
                     <button class="btn btn-warning" @click="goTasks(ex.id)">Перейти к задачам</button>

                  </div>
               </div>


            </div>
         </div>
      </div>


      <div class="modal fade" id="modalEditExperiment" tabindex="-1" aria-labelledby="modalExperiment"
         aria-hidden="true">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
                  <h1 class="modal-title fs-5" id="modalExperiment">Эксперимент №{{ curExNumber }}</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <form name="formEx" @submit.prevent="submitFromEx">

                     <input type="submit" id="submitForm" value="submit" style="display: none;">


                     <div class="mb-3">
                        <label for="experimentName" class="form-label fw-bold">Название эксперимента</label>
                        <input required type="text" class="form-control" id="experimentName" v-model="experimentName"
                           name="experimentName" autocomplete="off">
                     </div>
                     <div class="mb-3">
                        <label for="experimentDescription" class="form-label fw-bolder">Описание эксперимента</label>
                        <textarea required class="form-control" id="experimentDescription" rows="3" autocomplete="off"
                           v-model="experimentDescription" name="experimentDescription"></textarea>
                     </div>

                     <div class="form-check mb-3">
                        <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                           v-model="experimentEnabled" name="experimentEnabled">
                        <label class="form-check-label" for="flexCheckDefault">
                           доступен?
                        </label>
                     </div>

                     <p>Цвет</p>

                     <select v-model="experimentColorIndex" class="form-select" aria-label="Выбор цвета эксперимента"
                        required name="experimentColorIndex" id="experimentColorIndex"
                        :style="'font-weight: bold; color: gray; width: 120px; background-color:#' + experimentsColors[experimentColorIndex]">
                        <option v-for="c, cIdx in experimentsColors" :value="cIdx" :key="cIdx"
                           :style="'color: white; background-color:#' + c">{{ c }}</option>
                     </select>

                  </form>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отменить</button>
                  <button type="button" class="btn btn-primary" @click="saveData">Сохранить изменения</button>
               </div>
            </div>
         </div>
      </div>
   </div>

</template>

<style lang="scss">
.experiments {
   display: flex;
   flex-direction: column;

   .experimentItem {
      padding: 20px;
      margin: 20px 0;
      border-radius: 0.375rem;

   }

   .disabledExperiment {
      border-left: 24px solid gray;
      opacity: 0.5;
   }
}
</style>