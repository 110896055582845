import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView';
import TasksView from '../views/TasksView';
import MasksView from '../views/MasksView';
import ExperimentsView from '../views/ExperimentsView';

const routes = [
   {
      path: '/',
      name: 'login',
      component: LoginView
   },
   {
      path: '/tasks',
      name: 'tasks',
      component: TasksView
   },
   {
      path: '/masks',
      name: 'masks',
      component: MasksView
   },
   {
      path: '/experiments',
      name: 'experiments',
      component: ExperimentsView
   },

]

const router = createRouter( {
   history: createWebHistory( process.env.BASE_URL ),
   routes
} )

export default router
