// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
   apiKey: "AIzaSyD7hcDLE68_kjc039vxszW4t1ALgYba0yE",
   authDomain: "catabasis-dev.firebaseapp.com",
   projectId: "catabasis-dev",
   storageBucket: "catabasis-dev.appspot.com",
   messagingSenderId: "290332365608",
   appId: "1:290332365608:web:f2e15fa5278de36d83361d"
};

// Initialize Firebase
const app = initializeApp( firebaseConfig );

// init firestore service
const db = getFirestore( app );
const storage = getStorage( app );
export { db, storage }
