<script setup>

console.log( 'login' );

function submitForm() {
   console.log( 'submitForm' );

}

function goToExperiments() {
   location.pathname = '/experiments'
}

</script>

<template>
   <div class="p-3 mb-2 bg-primary bg-gradient text-white text-center pt-5" style="height:99vh">  

      <h1>
         Вход в CATABaSis
      </h1>
      <br>
      <br>
      <button @click="goToExperiments" class="btn btn-warning">Перейти к экспериментам</button>
      <br>
      <br>
      <br>
      <br>
      <br>
      <form @submit.prevent="submitForm">
         <input type="text" name="login" id="login">
         <input type="password" name="password" id="password">
         <input type="submit" value="Вход">
      </form>
   </div>
</template>

<style lang="scss">


</style>